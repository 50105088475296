const resources = {
  ja: {
    translation: {
      title: 'お問い合わせチャット｜楽天トラベル',
      announcementTitle: 'お知らせ',
      botError: {
        message: [
          '大変申し訳ございません。',
          '現在、自動応答サービスをご利用頂けません。',
        ],
      },
      cancelButton: '戻る',
      cancelOperatorMessage: 'オペレーターとのチャットをやめる',
      disconnectButton: {
        main: '切断', span: 'する'
      },
      fileDrop: ['こちらのエリアに','ファイルをドロップしてください。'],
      menus: {
        operator: {
          name: ['オペレーターとチャット'],
          submit: 'オペレーターとチャットしたい'
        },
        tel: {
          name: ['電話でお問い合わせ'],
          submit: '電話でお問い合わせしたい'
        },
        mail: {
          name: ['メールでお問い合わせ'],
          submit: 'メールでお問い合わせしたい'
        },
        reset: {
          name: ['最初に戻る'],
          submit: '最初に戻る'
        }
      },
      modal: {
        errorMessage: {
          required: 'この項目は入力必須です。',
          format: 'フォーマットが不正です。'
        },
        form: {
          email: {
            name: 'メールアドレス',
          },
          facilityName: {
            name: '宿泊施設様名',
          },
          lastName: {
            name: 'ご担当者様名（姓）',
            placeholder: '楽天',
          },
          firstName: {
            name: 'ご担当者様名（名）',
            placeholder: '太郎',
          },
        },
        message: {
          defaultMessage: [
            '宿泊施設様のご登録状況を確認してオペレーターが対応いたします。',
            'スムーズに対応させていただくためにも、お手数をおかけいたしますが以下のフォームに入力をお願いいたします。'
          ]
        },
        waitcount: ''
      },
      seeMore: 'もっと見る',
      sendButton: '送信',
      statusMessage: {
        connect: ['接続'],
        reconnect: ['再接続'],
        connectingOperator: ['対応可能なオペレーターを検索中です'],
        operator: ['オペレーターがチャットを開始しました'],
        disconnectOperator: ['オペレーターがチャットを終了しました'],
        disconnectUser: ['チャットの終了を受け付けました'],
        timeoutOperator: ['チャットのセッションが切れました'],
        disconnect: ['切断'],
        connectError: ['接続エラー'],
        reconnectError: ['再接続エラー'],
        reconnectFailed: ['再接続失敗'],
        tryReconnect: ['再接続試行'],
        reconnecting: ['再接続中({{data}}回目)']
      },
      typingMessage: 'がタイピング中',
      placeholder: {
        bot: 'お問い合わせ内容を入力してください。\n例）予約の変更を受けた',
        inputDisable: '表示されているボタンの中から選択してください。',
        selectCategory: 'カテゴリをボタンから選択してください。',
        inputEmail: 'こちらにメールアドレスを入力してください。',
        connectingOperator: 'オペレーターにお繋ぎします。しばらくお待ちください。',
        operator: 'メッセージを入力してください。',
        disconnect: 'ご利用ありがとうございました。\nチャットを行いたい場合は新たにチャットウィンドウを開いてください。',
        operatorError: 'お問い合わせ内容を入力してください。例)住所を変更したい'
      },
      profileName: {
        bot: ['楽天トラベル','施設サポートデスクチャット'],
        connectingOperator: ['検索中'],
        operator: '担当',
        disconnect: ['接続を切断しました。']
      }
    }
  },
}

module.exports = {
  resources
}
