/* initialState in Store is reading this setting
*/
const settings = {
  title: 'title',
  queue: null,
  displayQueueCategory: '',
  calcWaitCountType: 'ALL',
  wsurl: undefined,
  droparea: {visible: false, disabled:true, src:'/img/sendfile_drop.png', descriptions: 'fileDrop', fileimg:'/img/sendfile_message_icon_pc.png'},
  announcement: { title:"", msg:""},
  botError: {
    device: 'pc',
    isDisplay: false,
    messages: 'botError.message',
  },
  botMaintenance: { isMaintenance: false },
  chatSetting: {},
  connection: {},
  disconnect: {isDisplay: false, disabled: true},
  icon: {
    logoSrc: {
      ja: '/img/travel-sd_chatbot_icon_pc.png',
    },
    logoClassName: '',
    radius: '50%',
    bg: '#fff'
  },
  input: {
    value: '',
    placeholder: 'placeholder.bot',
    disabled: false,
  },
  laAvailability:{},
  menu: {
    device: 'pc',
    isActive: "",
    isDisplay: true,
    disabled: false,
    menus: [
      {
        id: 'operator',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.operator.name',
        submit: 'menus.operator.submit',
        link: undefined
      },
      {
        id: 'tel',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.tel.name',
        submit: 'menus.tel.submit',
      },
      {
        id: 'mail',
        isSkipSubmit: false,
        isDisplay: false,
        name: 'menus.mail.name',
        submit: 'menus.mail.submit',
      },
      {
        id: 'reset',
        isSkipSubmit: false,
        isDisplay: false,
        reset: false,
        name: 'menus.reset.name',
        submit: 'menus.reset.submit',
      }
    ]
  },
  message: {
    device: '',
    botIcon: '/img/travel-sd_chatbot_icon_pc.png',
    operatorIcon: '/img/operator_message_icon.png',
    radius: '50%',
    bg: '',
    contents: []
  },
  modalWindow: {
    isOpen: false,
    icon: {
      logoSrc: {
        ja: '/img/travel-sd_chatbot_icon_pc.png',
      },
    },
    displayQueueMap: {},
  },
  name: { name:'profileName.bot' },
  notification: {
    ignore: false,
    disableActiveWindow: false,
    askAgain: false,
    timeout: 5000,
    title: "",
    options: {
      body: '',
      icon:  '/img/operator_message_icon.png',
      lang:  '',
      image: "a",
      data: {}
    }
  },
  opponent: {},
  order: { orderNumber:'' },
  personel: { personel:'' },
  scroll: {isDisplay:''},
  shop: { shopId:'' },
  socket: "",
  socketAction: {
    upload: false
  },
  statusLamp: {
    status: "online",
    isDisplayStatus: true,
  },
  status : {
    text:"",
    classification: ""
  },
  submit: {opponent: 'bot', disabled: true, grayOut: false, screenSize: ''},
  suggestion:  {
    device: 'pc',
    index: 0,
    posX: "16px",
    items: [],
    inlineStyle: {
      transform: "translateX(0)",
    }
  },
  typing: {name:'',isDisplay:false},
  upload :  {
    isDisplay: false,
    icon: '/img/travel_sendimage_icon_sp.png',
    fileimg:'/img/travel_sendfile_message_icon_pc.png',
    accept: 'image/*',
    disabled: false,
  },
  userInfo: {
    categorization: {
      'chat_default': [
        { input: 'email', required: true },
        { input: 'facilityName', required: true },
        { input: 'lastName', required: true },
        { input: 'firstName', required: false }
      ],
    },
    inputs: {
      'email': { type: 'email', id: 'modalEmail', maxLength: 100, style: { pc: { width: '90%', height: '2.3em' }, sp: { width: '90%', height: '2.3em' } } },
      'facilityName': { type: 'text', id: 'modalFacilityName', maxLength: 190, style: { pc: {}, sp: {} } },
      'lastName': { type: 'text', id: 'modalLastName', maxLength: 95, style: { pc: {}, sp: {} } },
      'firstName': { type: 'text', id: 'modalFirstName', maxLength: 95, style: { pc: {}, sp: {} } },
    },
    message: {
      'chat_default': 'defaultMessage'
    }
  },
  salesforce: {
    id: ''
  }
}

module.exports = {
  settings
}